import { useState } from 'react';
import { Modal, Row } from 'antd';
import moment from 'moment';

import { formatDateTime } from '../../../../../../shared/common/helpers';
import { useAppSelector } from '../../../../../../shared/hooks';
import { visitDueSelectors } from '../../slice';

type Props = {
    patientIntakeId: number;
};

const MdLastVisitedBadge = ({ patientIntakeId }: Props) => {
    const visitDue = useAppSelector((state) => visitDueSelectors.selectById(state.census.visitDues, patientIntakeId));
    const [isVisible, setIsVisible] = useState<boolean>(false);

    const handleVisibleChange = async (visible: boolean) => {
        if (visitDue.mdLastVisited.visit != null) {
            setIsVisible(visible);
        }
    };

    return visitDue && visitDue.mdLastVisited && visitDue.mdLastVisited.visit && visitDue.mdLastVisited.visit.date.isValid() ? (
        <>
            <Row className="last-visited-date" onClick={() => handleVisibleChange(true)}>
                {formatDateTime(visitDue.mdLastVisited.visit.date)} ({moment().diff(visitDue.mdLastVisited.visit.date, 'days')})
            </Row>

            <Modal destroyOnClose open={isVisible} title="MD Last Visit" onCancel={() => handleVisibleChange(false)} footer={null}>
                Visited by <strong>{visitDue.mdLastVisited.visit.signedByUserName}</strong> on <strong>{formatDateTime(visitDue.mdLastVisited.visit.date)}</strong> (
                {moment().diff(visitDue.mdLastVisited.visit.date, 'days')} days ago)
            </Modal>
        </>
    ) : (
        <></>
    );
};

export default MdLastVisitedBadge;
